import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ data }) => {
  const [series, setSeries] = useState([3, 5, 10, 82]);
  const { colors, chartData } = data;
  const lables = chartData.map((x) => x.heading);
  console.log(lables);
  return (
    <div className="donut-chart-width" style={{ position: "relative" }}>
      <div className="chart-text">
        *EXPECTED BASE OFFERING <br />
        FINANCIAL ALLOCATIONS
      </div>
      <div id="chart" style={{ width: "100%", height: "100%" }}>
        <ReactApexChart
          options={{
            chart: {
              type: "donut",
            },
            plotOptions: {
              pie: {
                donut: {
                  size: "93%",
                  labels: {
                    show: false,
                  },
                  expandOnClick: false,
                  spacing: 5,
                  dataLabels: {
                    minAngleToShowLabel: 10,
                  },
                },
              },
            },
            title: {
              verticalAlign: "middle",
              floating: true,
            },
            stroke: {
              width: 0,
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
              fontSize: "14px",
              position: "right",
              top: "0px",
              offsetX: -90,
              offsetY: -10,
              width: 150,
              reverse: true,
              itemMargin: {
                horizontal: 5,
                vertical: 30,
              },
            },
            colors: colors,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: "100%",
                  },
                  legend: {
                    show: false,
                  },
                },
              },
              {
                breakpoint: 1070,
                options: {
                  chart: {
                    width: "190%",
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
          }}
          series={series}
          type="donut"
        />
      </div>
    </div>
  );
};

export default ApexChart;
