import React from "react";

function HrLine() {
  return (
    <div class="container d-flex justify-content-center align-items-center">
      <div class="horizontal-line"></div>
    </div>
  );
}

export default HrLine;
