import React, { useRef, useState } from "react";

function CollectorPass() {
  return (
    <div className="sec section-terms">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h5 className="text-uppercase">
            FRACTIONAL {"//"} FRACTIONAL Collectors Pass
          </h5>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-lg-4 col-md-5 col-sm-12 h2-m">
          <img
            className="pt-2 pb-3"
            src="/pass.gif"
            height="auto"
            width="100%"
            alt="pass"
          />
          <button
            type="button"
            className="btn btn-primary btn-lg btn-keep btn-w-40 mr-10 text-uppercase"
          >
            mint on cardano
          </button>
          <button
            type="button"
            className="btn btn-primary btn-lg btn-keep btn-w-40 text-uppercase"
          >
            mint on polygon
          </button>
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 h2-m text-justyfy">
          <h2 className="text-uppercase f-20 pb-3 text-gold">
            the fractional collectors pass
          </h2>
          <p className="text-p">
            The FRACTIONAL collectors access pass is the foundational piece to
            our platform ecosystem. This non-fungible token will be minted on
            Cardano and Polygon respectively. The pass will allow you: 
          </p>
          <ul className="text-p">
              <li>Beta access to the FRACTIONAL asset management platform.</li>
            <li>The FRACTIONAL passive staking system for earning $FRAC.</li>
            <li>Early access to mint "fractions".</li>
            <li>Access to $FRAC only "fractions".</li>
          </ul>
          <p className="text-p">
            FRACTIONAL has preliminary plans to mint a maximum of 2,000 collectors
            access passes on each chain respectively. The price target for the
            FRACTIONAL collectors access pass is roughly $40 USD.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CollectorPass;
