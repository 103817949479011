import React from "react";
import "./css/connect-fractional.css";

const FractionalConnect = () => {
  return (
    <div className='row m-0 connectBox'>
      <div className='col-lg-3 col-md-12 user-sidebar-nav'>
        <div className='row user-info'>
          <div className='col-auto'>
            <img
              className='userimg'
              src='/images.png'
              alt=''
            />
          </div>
          <div className='col mt-auto mb-auto px-0'>
            RENDR VAULT
            <div className='address'>addr...............xyz</div>
            <div className='value-no'>213,498 $FRAC</div>
          </div>
        </div>
        <ul>
          <li>
            <a href='#'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-person-bounding-box'
                viewBox='0 0 16 16'
              >
                <path d='M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5' />
                <path d='M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0' />
              </svg>{" "}
              PROFILE
            </a>
          </li>
          <li>
            <a href='#'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-coin'
                viewBox='0 0 16 16'
              >
                <path d='M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518z' />
                <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                <path d='M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12' />
              </svg>{" "}
              ASSETS
            </a>
          </li>
          <li>
            <a href='#'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-clipboard2-data'
                viewBox='0 0 16 16'
              >
                <path d='M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5z' />
                <path d='M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z' />
                <path d='M10 7a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zm-6 4a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm4-3a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1' />
              </svg>{" "}
              STAKING
            </a>
          </li>
          <li>
            <a href='#'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-globe-americas'
                viewBox='0 0 16 16'
              >
                <path d='M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z' />
              </svg>{" "}
              GOVERNANCE
            </a>
          </li>
          <li>
            <a href='#'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                fill='currentColor'
                className='bi bi-headset'
                viewBox='0 0 16 16'
              >
                <path d='M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5' />
              </svg>{" "}
              SUPPORT
            </a>
          </li>
        </ul>
      </div>

      <div className='col-lg-9 col-md-12 user-right-side'>
        <div className='row m-0'>
        <div className='col-12 text-start'>
                {" "}
                <h1 className='mt-0'>Hello, RENDR</h1>
              </div>
          <div className='col-lg-8 col-md-12'>
            <div className='row g-3'>
              <div className='col-md-4'>
                <div className=' card-info'>
                  <img
                    className='c-in-img'
                    src='/image-001.jpg'
                    alt=''
                  />
                  <span>#905847</span>
                  <h2> Berl var. Emerald</h2>
                  <span> Total Supply 400 Fractions</span>
                  <button className='btn btn-primary hoverBtn'>MINT</button>
                </div>
              </div>
              <div className='col-md-4'>
                <div className=' card-info'>
                  <img
                    className='c-in-img'
                    src='/image-001.jpg'
                    alt=''
                  />
                  <span>#905847</span>
                  <h2> Berl var. Emerald</h2>
                  <span> Total Supply 400 Fractions</span>
                  <button className='btn btn-primary'>MINT</button>
                </div>
              </div>
              <div className='col-md-4'>
                <div className=' card-info'>
                  <img
                    className='c-in-img'
                    src='/image-001.jpg'
                    alt=''
                  />
                  <span>#905847</span>
                  <h2> Berl var. Emerald</h2>
                  <span> Total Supply 400 Fractions</span>
                  <button className='btn btn-primary'>MINT</button>
                </div>
              </div>
            </div>

            <h1 className='text-start'>FRACTIONAL // ROCKS NEWS</h1>

            <div className='row g-3'>
              <div className='col-md-12'>
                <div className='card-info text-start'>
                  <div className='row'>
                    <div className='col-auto'>
                      <img
                        className='card-img mb-0'
                        src='/image-001.jpg'
                        alt=''
                      />
                    </div>
                    <div className='col ps-0 mt-auto mb-auto'>
                      <h2 className='mt-0'>FRACTIONAL TEAM IN TUCSON</h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type
                        <a
                          href='#'
                          className='c-link'
                        >
                          READ MORE...
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-md-12'>
                <div className='card-info text-start'>
                  <div className='row'>
                    <div className='col-auto'>
                      <img
                        className='card-img mb-0'
                        src='/image-001.jpg'
                        alt=''
                      />
                    </div>
                    <div className='col ps-0 mt-auto mb-auto'>
                      <h2 className='mt-0 text-start'>
                        FRACTIONAL TEAM IN TUCSON
                      </h2>
                      <p>
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type
                        <a
                          href='#'
                          className='c-link'
                        >
                          READ MORE...
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-lg-4 col-md-12'>
            <div className='right-content-list'>
              <div className='col-12 text-center'>
                <button className='btn'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    fill='currentColor'
                    className='bi bi-caret-up-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z' />
                  </svg>
                </button>
              </div>

              <div className='row pb-15'>
                <div className='col-auto'>
                  <img
                    className='img-list'
                    src='/image-001.jpg'
                    alt=''
                  />
                </div>
                <div className='col text-start ps-0'>
                  <span>#0093</span>
                  <br />
                  Tourmaline Var…
                  <div className='row py-1'>
                    <div className='col'>1OWNED</div>
                    <div className='col text-end'>FLOOR</div>
                  </div>
                  <div className='row'>
                    <div className='col'>FLOOR</div>
                    <div className='col text-end'>42A</div>
                  </div>
                </div>
              </div>
              <div className='row pb-15'>
                <div className='col-auto'>
                  <img
                    className='img-list'
                    src='/image-001.jpg'
                    alt=''
                  />
                </div>
                <div className='col text-start ps-0'>
                  <span>#0093</span>
                  <br />
                  Tourmaline Var…
                  <div className='row py-1'>
                    <div className='col'>1OWNED</div>
                    <div className='col text-end'>FLOOR</div>
                  </div>
                  <div className='row'>
                    <div className='col'>FLOOR</div>
                    <div className='col text-end'>42A</div>
                  </div>
                </div>
              </div>
              <div className='row pb-15'>
                <div className='col-auto'>
                  <img
                    className='img-list'
                    src='/image-001.jpg'
                    alt=''
                  />
                </div>
                <div className='col text-start ps-0'>
                  <span>#0093</span>
                  <br />
                  Tourmaline Var…
                  <div className='row py-1'>
                    <div className='col'>1OWNED</div>
                    <div className='col text-end'>FLOOR</div>
                  </div>
                  <div className='row'>
                    <div className='col'>FLOOR</div>
                    <div className='col text-end'>42A</div>
                  </div>
                </div>
              </div>

              <div className='col-12 text-center'>
                <button className='btn'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    fill='currentColor'
                    className='bi bi-caret-down-fill'
                    viewBox='0 0 16 16'
                  >
                    <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' />
                  </svg>
                </button>
              </div>
            </div>

            <div className='news-card'>
              <h2>INDUSTRY NEWS</h2>
              <ul>
              <li><a href='#'>DeBeers starts new blockchain…</a></li>
              <li><a href='#'>DeBeers starts new blockchain…</a></li>
              <li><a href='#'>DeBeers starts new blockchain…</a></li>
              <li><a href='#'>DeBeers starts new blockchain…</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FractionalConnect;
