import React from "react";

function footer() {
  return (
    <>
      <div className="footer">
        <div className="footer-details">
          <div className="footer-list">
            <ul>
              <li>
                {" "}
                <a
                  className="icons agency-fb-font-family-regular text-gold"
                  href="/terms-and-conditions"
                >
                  TERMS & CONDITIONS{" "}
                </a>
              </li>
              <li className="border-list border-list-right-0">
                {" "}
                <a
                  className="icons agency-fb-font-family-regular text-gold"
                  href="/privacy-policy"
                >
                  PRIVACY POLICY{" "}
                </a>
              </li>
            </ul>
          </div>
          <div className="copy-right">
            <h5>&copy; 2023 Fractional LTD . All Rights Reserved</h5>
          </div>
        </div>
        <div className="footer-icons">
          {/* <div>
            {" "}
            <a href="#">
              <img src="/icons/linkedin.png" alt="linkedin" />
            </a>{" "}
          </div> */}
          {/* <div>
            {" "}
            <a href="#">
              <img src="/icons/facbook.png" />
            </a>{" "}
          </div> */}
          <div>
            <a href="#">
              <img src="/icons/twiter.png" />
            </a>
          </div>
          <div>
            {" "}
            <a href="#">
              <img src="/icons/discord.png" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default footer;
