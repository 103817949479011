import React from "react";
import { useParams } from "react-router-dom";

import OfferChart from "./offer-chart";
import offerData from "../data/fractional-offer-data";
import PageNotFound from "./page-not-found";

const FractionalOffer = () => {
  const { id } = useParams();

  const data = offerData.find((x) => x.id === id);
  const chart = data?.chartData;
  return (
    <>
      {data ? (
        <div className="section-offers">
          <div className="row m-0">
            <div className="col-sm-12 p-0">
              <h5>
                FRACTIONAL {"//"} OFFERING #{data.id}
              </h5>
              <h2 className="text-uppercase h2-m text-gold">
                {data.title}
                {data.bracketsTitle ? `(${data.bracketsTitle})` : ""}
              </h2>
            </div>
          </div>
          <div className="row m-0 reverse-columns">
            <div className="col-lg-8 col-md-12 col-sm-12 p-0">
              {data.paragraph.map((p) => (
                <p>{p}</p>
              ))}

              <h2 className="text-uppercase text-gold">
                total offering / ${data.price} usd
              </h2>
              {/* <div className="row m-0">
                <div className="col-lg-4 col-sm-12 col-md-12 mt-23">
                  <OfferChart data={data} />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 chart-list-f pt-10px">
                  <ul className="ul-style-list">
                    <li>
                      <div className="list-item-container">
                        <img src="/dot.png" alt="Image 1" />
                        <h1 className="chart-fractional-offer">
                          {" "}
                          {chart.data1.heading} - {chart.data1.percentage}%
                        </h1>
                      </div>
                      <p className="available-asset-offer mb-0 text-black">
                        {chart.data1.subHeading}
                      </p>
                    </li>

                    <li>
                      <div className="list-item-container">
                        <img src="/blue-dot.png" alt="Image 1" />
                        <h1 className="chart-fractional-offer">
                          {" "}
                          {chart.data2.heading} - {chart.data2.percentage}%
                        </h1>
                      </div>

                      <p className="available-asset-offer mb-0 ">
                        {chart.data2.subHeading}
                      </p>
                    </li>
                    <li>
                      <div className="list-item-container">
                        <img src="/pink-dot.png" alt="Image 1" />
                        <h1 className="chart-fractional-offer">
                          {" "}
                          {chart.data3.heading} - {chart.data3.percentage}%
                        </h1>
                      </div>

                      <p className="available-asset-offer mb-0 ">
                        {chart.data3.subHeading}
                      </p>
                    </li>
                    <li>
                      <div className="list-item-container">
                        <img src="/yellow-dot.png" alt="Image 1" />
                        <h1 className="chart-fractional-offer">
                          {" "}
                          {chart.data4.heading} - {chart.data4.percentage}%
                        </h1>
                      </div>

                      <p className="available-asset-offer mb-0  ">
                        {chart.data4.subHeading}
                      </p>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 p-0">
              <h6 className="h6-gray text-gold">
                ESTIMATED AVAILABILITY / {data.availabilityDate}
              </h6>
              <button type="button" className="btn btn-primary btn-lg btn-keep">
                KEEP ME UPDATED
              </button>

              <img
                className="pt-2 pb-3"
                src={data.src}
                height="80%"
                width="100%"
                alt="logo"
              />
            </div>
          </div>
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
};

export default FractionalOffer;
