import React, { useRef, useState } from "react";

function Terms() {
  return (
    <div className="sec section-terms">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h5>FRACTIONAL {"//"} TERMS AND CONDITIONS</h5>
          <h2 className="text-uppercase h2-m text-gold">
            TERMS AND CONDITIONS
          </h2>
          <h5 className="text-gold text-uppercase">tERMS OF USE:</h5>
          <p className="text-justify text-p">
            The Services offed by Fractional, LTD / fractional.rocks are
            reserved for those over 18 years of age and by accessing the
            Services you acknowledge that you are at least 18 years of age and
            have the right and capacity to enter into these Terms & Conditions.
            The Terms & Conditions may be updated from time to time without any
            notice to you and you accept any future material changes, with or
            without notice, as acknowledged by your continued use of Fractional,
            LTD / fractional.rocks. You also consent to receive communications
            from us electronically, and agree that these communications should
            satisfy any legal requirements that such communications be in
            writing.
          </p>
          <h5 className="text-gold text-uppercase">INVESTMENT DISCLAIMER: </h5>{" "}
          <p className="text-justify text-p">
            Nothing contained in the Services, including any content created by
            Fractional, LTD / fractional.rocks, constitutes investment,
            financial, accounting, tax, or legal advice and should not be
            considered an offer or solicitation or advise to buy and sell
            securities. Any decision based on the information obtained through
            Fractional, LTD / fractional.rocks are the sole responsibility of
            the user of the Services. All users accessing the Services do so of
            their own accord and are responsible for any and all compliance with
            local laws and regulations.
          </p>
          <h5 className="text-gold text-uppercase">
            Investing involves risk:{" "}
          </h5>{" "}
          <p className="text-justify text-p">
            Market risks, inflation, political environment, and acts of God may
            cause investments to lose value. Always consider your own
            objectives, resources, and risk tolerance before purchasing
            non-fungible token and other blockchain products. Investing in
            emerging technology involves higher risks than traditional
            investments and may have different financial and tax implications.
            By using the Services you acknowledge that with respect to any
            investment made through the Services:
          </p>
          <ul>
            <li className="text-justify text-p">
              You have read and understand the risks, and are aware of higher
              risks associated with non-fungible token and blockchain
              technology.
            </li>
            <li className="text-justify text-p">
              You will use your own judgement to make any decision prior to
              investing in any of the Services.
            </li>
            <li className="text-justify text-p">
              You are solely responsible for complying with the law regarding
              any transactions, including the determination of its compliance
              with local law.
            </li>
            <li className="text-justify text-p">
              You will obtain professional advice to protect your interests
              including legal, financial, accounting, and tax advice.
            </li>
            <li className="text-justify text-p">
              You understand that Fractional, LTD / fractional.rocks is not an
              investment advisor and is in no way responsible for the success or
              failure of any offering of the Service.
            </li>
            <li className="text-justify text-p">
              You are not relying on any entity of than the issuer of the
              Service.
            </li>
          </ul>
          <h5 className="text-gold text-uppercase mt-3">LICENSE:</h5>
          <p className="text-justify text-p">
            Subject to your compliance with these Terms & Conditions, we grant
            you a limited, non-exclusive, non-transferable right to access and
            use the Services for your personal and non-commercial use. All
            information provided by you must be correct, current, and complete
            as a condition of this license. The Services are used at your own
            risk. Fractional, LTD / fractional.rocks reserves all additional
            rights not expressly granted. The services extend to any services,
            applications, passes, coins, tokens, offered by Fractional, LTD /
            fractional.rocks even if supplied to you by a third party such as an
            app store or marketplace. You are responsible for having the
            hardware, software, and internet access sufficient to use the
            Services and are responsible for any charges incurred during usage.
            We make no representations or warranties for device compatibility.
          </p>
          <h5 className="text-gold text-uppercase">SERVICE GUARANTEE:</h5>{" "}
          <p className="text-justify text-p">
            Although our intention is to make the Services available at all
            times, we may occasionally temporarily or permanently disable the
            Services or any part thereof for scheduled maintenance or change of
            scope. We reserve the right to discontinue, suspend, remove,
            disable, or to impose limits on any part or access to the Services.
            In no event will Fractional, LTD / fractional.rocks be liable for
            discontinuance, change, suspension, or removal of any part of the
            Services temporarily or permanently. We may also restrict access to
            various parts of the Services from time to time.
          </p>
          <h5 className="text-gold text-uppercase">ACCOUNT:</h5>
          <p className="text-justify text-p">
            {" "}
            Accounts may only be accessed with a compatible wallet containing a
            qualifying non-fungible token. You agree to only use a wallet you
            own, and token(s) you own to obtain access to the Services. In an
            effort to ensure we protect and properly administer the Services, we
            have the right to refuse access to the Services at any time for any
            or no reason.
          </p>
          <h5 className="text-gold text-uppercase">PROHIBITED USE: </h5>{" "}
          <p className="text-justify text-p">
            You may use the Services only for their intended lawful purposes in
            accordance with these Terms & Conditions. You agree not to:
          </p>
          <ul>
            <li className="text-justify text-p">
              Exploit any “token-gated” access to the Services.
            </li>
            <li className="text-justify text-p">
              Knowingly receive and use any stolen assets to access the
              Services.
            </li>
            <li className="text-justify text-p">
              To record, harvest, or collect any information provided to you by
              the use of the Services.
            </li>
            <li className="text-justify text-p">
              Maliciously attack the Services in any form or manner.
            </li>
          </ul>
          <h5 className="text-gold text-uppercase mt-3">
            INTELLECTUAL PROPERTY:
          </h5>{" "}
          <p className="text-justify text-p">
            The Services, trademarks, logos, branding, and content created by
            Fractional, LTD / fractional.rocks is the propriety property of
            Fractional, LTD / fractional.rocks and may not be used in any form
            without express written permission.
          </p>
          <h5 className="text-gold text-uppercase">WARRANTY:</h5>{" "}
          <p className="text-justify text-p">
            Fractional, LTD / fractional.rocks disclaims all warranties of any
            kind, express, implied, or otherwise, including any warranties of
            the merchantability, quiet enjoyment, accuracy and fitness for a
            particular purpose. The Services are provided on an “as is” and “as
            available” basis. Fractional, LTD / fractional.rocks does not
            guarantee the accuracy, completeness, or usefulness of the Services
            of any related content. You agree to use the Services at your own
            risk.
          </p>
          <h5 className="text-gold text-uppercase">LIABILITY: </h5>
          <p className="text-justify text-p">
            You acknowledge that your access to and use of the Services is at
            your sole risk. In on event shall Fractional, LTD /
            fractional.rocks, it’s owners, managers, officers, directors,
            members, shareholders, affiliates, subsidiaries, employees,
            contractors, agents, representatives, or licensors be liable for any
            indirect, incidental, special, exemplary, or consequential damagers,
            including damages for loss of profit, loss of data, business
            interruptions, or any commercial damages or losses. In no event
            shall Fractional, LTD / fractional.rocks total liability to you for
            all damages ever exceed the greater the total amount paid by you for
            the Services in the six (6) months preceding the claim, or
            twenty-five thousand dollars ($25,000). It is the intention of you
            and us that his provision be construed by a court as being the
            broadest limitation of liability consistent with applicable law.
          </p>
          <h5 className="text-gold text-uppercase">GOVERNING LAW:</h5>
          <p className="text-justify text-p">
            All Terms & Conditions herein shall be governed by the laws of Hong
            Kong. You agree to submit to the jurisdiction of Hong Kong. Your use
            of these products and services may be subject to other local, state,
            national, or international law.
          </p>
          <h5 className="text-gold text-uppercase">INDEMNIFICATON:</h5>{" "}
          <p className="text-justify text-p">
            You agree to indemnify and hold harmless Fractional, LTD and its
            subsidiaries, affiliates, and licensors as well as their respective
            owners, officers, directors, members, shareholders, contractors,
            agents, licensors, and token holders from an against any claims,
            liabilities, damages, judgements, awards, losses, costs, expenses
            and/or fees resulting from any violation of these Terms &
            Conditions, your purchase and use of Fractional tokens, your
            violation of any third party right, any claim that your use caused
            damage to a third party, or from any decision you make based on or
            through Fractional / fractional.rocks or any other product or
            service made available.{" "}
          </p>
          <h5 className="text-gold text-uppercase">ARBITRATION:</h5>
          <p className="text-justify text-p">
            For any dispute with Fractional, LTD / fractional.rocks you agree
            first to contact
            <a className="text-gold" href="mailto:disputes@fractional.rocks">
              {" "}
              disputes@fractional.rocks
            </a>{" "}
            and attempt to resolve the dispute with us formally. In the unlikely
            event that the parties fail to resolve the dispute after ninety (90)
            days, we each agree to resolve any claim or controversy by binding
            arbitration. Any judgement on the award rendered by the arbitrator
            may be entered in any court of competent jurisdiction. Nothing in
            this section shall be deemed as preventing Fractional, LTD /
            fractional.rocks from seeking injunctive relief or other equitable
            relief from the court as necessary to prevent actual or threatened
            infringement, misappropriation, or violation of our data security,
            intellectual property, or other proprietary rights.
          </p>
          <h5 className="text-gold text-uppercase">
            CLASS ACTION & JURY WAIVER:
          </h5>{" "}
          <p className="text-justify text-p">
            All claims must be brought in the parties individual capacity, and
            not as a plaintiff or class member in an purported class action,
            collection action, private attorney general action, or other
            representative proceedings. This waiver applies to class
            arbitration, and no arbitrator shall consolidate more than one (1)
            persons claims. You agree that you and Fractional, LTD /
            fractional.rocks are waiving the right to trial by jury or to
            participate in a class action.
          </p>
          <h5 className="text-gold text-uppercase">
            CHANGES TO TERMS & CONDITIONS:{" "}
          </h5>{" "}
          <p className="text-justify text-p">
            We reserve the right to modify or change these Terms & Conditions,
            for any reason, at any time, with or without notice.
          </p>
          <h5 className="text-gold text-uppercase">TERMINATION:</h5>{" "}
          <p className="text-justify text-p">
            Fractional, LTD / fractional.rocks may terminate your access to all
            or any part of the Services at any time, with or without reason or
            cause, effective immediately.
          </p>
          <h5 className="text-gold text-uppercase">CONTACT US:</h5>
          <p className="text-justify text-p">
            All comments and/or concerns regarding our Terms & Conditions should
            be sent to{" "}
            <a className="text-gold" href="mailto:legal@fractional.rocks">
              {" "}
              legal@fractional.rocks
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
