import React from "react";

function Faqs() {
  return (
    <div className="sec section-terms faq-sec">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h5 className="text-uppercase">FRACTIONAL {"//"} Faq's</h5>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12 h2-m">
          <h2 className="text-uppercase f-20 pb-3 text-gold">faq's</h2>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item mb-3">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  WHAT IS FRACTIONAL?
                  {/* <span class="icon"></span> */}
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  FRACTIONAL, LTD (fractional.rocks), also known as "FRACTIONAL" , is a pioneering asset tokenization and fractionalization platform specializing in the rare mineral and gemstone industries. Harnessing cutting-edge blockchain and NFT technology, we democratize ownership of these precious assets. Traditionally, these luxuries were reserved for the wealthy, but through tokenization and fractionalization, we bring this opportunity within reach of everyone, regardless of their class, financial status, or location.
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  WHY IS FRACTIONALIZATION IMPORTANT?
                  <span class="icon"></span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                 Blockchain empowers all, irrespective of financial status, class, or location, and Fractional utilizes this technology to make the dream of owning rare and valuable minerals and gemstones a reality. At Fractional we want to remove gatekeepers and increase access to affordable ownership for everyone!
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  ARE THESE REAL MINERALS / GEMSTONES?
                  <span class="icon"></span>
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  YES!  Your "Fractions" are directly tied to real gemstone and mineral specimens!
                </div>
              </div>
            </div>
            <div class="accordion-item mb-3">
              <h2 class="accordion-header" id="headingfour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  HOW DO I BUY / SELL FRACTIONS?
                  <span class="icon"></span>
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingfour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Due to the nature of non-fungible tokens, and blockchain technology, you are free to buy and sell fractions on a variety of platforms and marketplaces.  We expect to launch our own marketplace for our holders in 2024.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
