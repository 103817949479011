import React from "react";
import { useNavigate } from "react-router-dom";

import ReactCharts from "./react-chart";
import Swipper from "./swipper";
import Team from "./Team";
import HrLine from "./hr-line";

const Home = ({ children }) => {
  const COLLECTORS_PASS = "/collectors-pass";
  const navigate = useNavigate();

  const colors = ["#a07e62", "#c39960", "#745b5f", "#5b465a"];
  const chartData = [
    {
      heading: "ASSET ACQUISITION",
      percentage: "82",
      p: "Asset locating, acquisition, transportation, labor, etc.",
      color: colors[3],
    },
    {
      heading: "ASSET MANAGEMENT",
      percentage: "10",
      p: "Asset management, storage, display, insurance, etc.",
      color: colors[2],
    },
    {
      heading: "MISCELLANOUS EXPENSES",
      percentage: "5",
      p: "All additional expenses including team payroll, unexpected costs, etc.",
      color: colors[1],
    },
    {
      heading: "ADMINSITRATIVE FEES",
      percentage: "3",
      p: "Structure of offering, organization, operations, photography, graphic design, bookkeeping, etc.",
      color: colors[0],
    },
  ];

  const handleNavigate = () => {
    // Use history.push to navigate to a different route
    navigate(COLLECTORS_PASS);
  };

  const openNewTabCardano = () => {
    // Specify the link you want to open in a new tab
    const linkToOpen = "./cardano-minting";

    // Open a new tab with the specified link
    window.open(linkToOpen, "_blank");
  };

  return (
    <>
      <div
        className='modal login fade'
        id='exampleModal'
        tabIndex='-1'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-body login'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
              <h2>CHOOSE YOUR PLATFORM</h2>

              <div className='row mt-3'>
                <div className='col-6'>
                  <a
                    className='select-logo'
                    href='#'
                  >
                    <img
                      src='/full-polygon-logo.webp'
                      alt='Polygon Logo'
                    />
                  </a>
                </div>
                <div className='col-6'>
                  {" "}
                  <a
                    className='select-logo cursor-pointer'
                    onClick={openNewTabCardano}
                  >
                    <img
                      src='/cardano.png'
                      alt='Cardano Logo'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='header-section'>
        <div className='d-flex flex-column justify-content-between align-items-center'>
          <div className='fractional-section text-center'>
            <img
              // className="logo-img"
              src='/header-logo.png'
              alt=''
              width='50%'
            />
            <p className='mb-0 blockChain-text'>
              BLOCKCHAIN BASED TOKENIZED AND FRACTIONAL
            </p>
            <h3 className='gemstone-text agency-fb-font-family-regular'>
              GEMSTONE & MINERAL OWNERSHIP
            </h3>
          </div>
          <div className='d-flex justify-content-center btn-m'>
            <button
              type='button'
              className='btn btn-gradient btn-primary btn-lg'
              data-bs-toggle='modal'
              data-bs-target='#exampleModal'
            >
              MINT PASS 
            </button>

            <button
              onClick={handleNavigate}
              type='button'
              className='btn btn-outline-primary btn-lg'
            >
              WHY BUY A COLLECTORS PASS?
            </button>
          </div>
          <p className='mt-10 powerBy-text'>POWERED BY</p>
          <div className='d-flex justify-content-center logo power-logo'>
            <img
              src='/full-polygon-logo.webp'
              alt='Polygon Logo'
            />
            <img
              src='/cardano.png'
              alt='Cardano Logo'
            />
          </div>
        </div>
      </section>
      <HrLine />
      <section
        id='vision'
        className='fractional-section-t owenership-section'
      >
        <p className='mb-0 heading-light '>OUR UNIQUE</p>
        <h1 className='heading-white agency-fb-font-family-regular'> VISION</h1>
        <div className='col-lg-12 col-md-12 col-sm-12 d-flex owner-ship p-0'>
          <div className='col-lg-12 col-md-12 col-sm-12 p-0'>
            <p className='owenership-data'>
              Blockchain empowers all, irrespective of financial status, class,
              or location, and Fractional utilizes this technology to make the
              dream of owning rare and valuable minerals and gemstones a
              reality. At Fractional we want to remove gatekeepers and increase
              access to affordable ownership for everyone!
            </p>
            <p className='owenership-data new-p'>
              Due to renewed and increased world-wide interest in high end
              mineral specimen collecting, we provide a unique opportunity to
              own tokenized and/or fractional stakes in mineral and gemstone
              assets. Leveraging our extensive mineralogical and mining
              experience, we source spectacular mineral and gemstone specimens,
              tokenize and/or fractionalize them using blockchain technology,
              and make them available for you to purchase. This makes ownership
              a reality, and allows you to build a collection of rare and
              valuable assets.
            </p>
          </div>
        </div>
      </section>
      <HrLine />

      <section
        id='fractions'
        className='section-slider'
      >
        <div className='swipper header-b-3'>
          <p className='heading-light mb-0 text-uppercase'>
            currently AVAILABLE
          </p>
          <h1 className='heading-white heading-white agency-fb-font-family-regular'>
            {" "}
            FRACTIONS
          </h1>
        </div>
        <Swipper />
      </section>
      <HrLine />

      <section
        id='team'
        className='owenership-section'
      >
        <p className='mb-0 heading-light text-uppercase'>MEET our</p>
        <h1 className='heading-white agency-fb-font-family-regular text-uppercase'>
          {" "}
          FRACTIONAL TEAM
        </h1>
        <Team />
      </section>

      <HrLine />

      <section
        id='socials'
        className='section-slider '
      >
        <div className='swipper'>
          <p className='heading-light mb-0'>FRACTIONAL</p>
          <h1 className='heading-white mt-10 agency-fb-font-family-regular'>
            {" "}
            SOCIAL MEDIA
          </h1>
        </div>
        <div
          className='d-flex justify-content-center'
          style={{ gap: "10px" }}
        >
          {/* <a href="#">
            {" "}
            <img className="social-images" src="./icons/linkedin.png" alt="" />
          </a> */}
          {/* <a href="#">
            {" "}
            <img className="social-images" src="./facbook.png" alt="" />
          </a> */}
          <a href='https://twitter.com/fractionalrocks'>
            {" "}
            <img
              className='social-images'
              src='./icons/twiter.png'
              alt=''
            />
          </a>
        
        </div>
      </section>
      <HrLine />

      <section
        id='tokenomics'
        className=' section-slider owenership-section'
      >
        <p className='mb-0 heading-light'>FRACTIONAL</p>
        <h1 className='heading-white agency-fb-font-family-regular '>
          {" "}
          ALLOCATIONS
        </h1>
        <div
          className='col-lg-12 col-md-12 col-sm-12 chart-header'
          style={{ marginTop: "100px" }}
        >
          <div className='col-lg-6 col-md-12 col-sm-12 donut-chart'>
            <ReactCharts data={{ colors, chartData }} />
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 chart-list'>
            <ul className='custom-list'>
              {chartData.map((item, index) => (
                <>
                  <li className={"cl-" + index}>
                    <div className='list-item-container'>
                      <h1 className='chart-fractional'>
                        {" "}
                        {item.heading} - {item.percentage}%
                      </h1>
                      <p className='available-asset mb-0 text-lightly'>
                        {item.p}
                      </p>
                    </div>
                  </li>
                  <style>
                    {`
              .custom-list li.cl-${index}::before {
                content: "●";
                color: ${item.color};
                display: inline-block;
                width: 1em;
                text-align: center;
                margin-right: 0.5em;
                margin-top: -30px;
                font-size: 30px;
              }
            `}
                  </style>
                </>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <HrLine />

      <section className='section-legal'>
        <div className='swipper'>
          <p className='heading-light mb-0'>LEGAL</p>
          <h1 className='heading-white mt-10 agency-fb-font-family-regular text-uppercase'>
            {" "}
            Disclaimer
          </h1>
        </div>
        <div
          className='d-flex'
          style={{ gap: "10px" }}
        >
          <p className='text-lightly legal-notice-text'>
            Fractional, LTD ("Fractional") operates the fractional.rocks website
            and IS NOT a broker-dealer or investment advisor. All users must
            acknowledge and accept the high risk associated with blockchain and
            non-fungible token techology. These risks can include lack of market
            liquidity resulting in no ability to resell your products or
            services. You must have the ability to bear a total loss of purchase
            price without any change in your lifestyle. This site may contain
            forward looking statements which are not guaranteed. All users must
            make their own determination of whether or not to purchase any
            product or services from Fractional, LTD / (fractional.rocks) based
            on their own evaluation and analysis. Past performance may not be
            indicative of future results. All potential securities related
            activity is governed by the laws of Hong Kong. For more information
            about Fractional, and our offerings, please review our{" "}
            <span className='t-link'>
              <a
                className='ractional-rocks-color text-gold'
                href='/terms-and-conditions'
              >
                {" Terms & Conditions"}
              </a>
            </span>
            .
          </p>
        </div>
      </section>
    </>
  );
};
export default Home;
