import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import customJs from "../js/custom";

const connect = "/connect";

const Nav = () => {
  const navigate = useNavigate();
  const togglerRef = useRef(null);

  useEffect(() => {
    customJs();
  }, []);

  const handleNavigate = () => {
    navigate(connect);
  };
  const links = [
    {
      name: "WHITEPAPER",
      link: "https://fractional.rocks/whitepaper.pdf",
    },
    {
      name: "vision",
      link: "/#vision",
    },

    {
      name: "team",
      link: "/#team",
    },
    {
      name: "Fractions",
      link: "/#fractions",
    },
    {
      name: "ALLOCATIONS",
      link: "/#tokenomics",
    },

    {
      name: "socials",
      link: "/#socials",
    },
    {
      name: "FAQ'S",
      link: "/faqs",
    },
    {
      name: "CONTACT",
      link: "/contact-us",
    },
  ];

  return (
    <>
      <nav class="navbar sticky-top navbar-expand-lg navbar-light agency-fb-font-family-regular fixed-top">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img className="header-logo" src="/header-logo.png" alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
              {links.map((link) => (
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    aria-current="page"
                    href={link.link}
                  >
                    {link.name}
                  </a>
                </li>
              ))}

              {/* <li class="nav-item">
                
              </li> */}
              <li class="nav-item">
                <button
                  onClick={handleNavigate}
                  class="btn btn-primary btn-connect text-uppercase"
                >
                  Connect
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Nav;
