import React, { useRef, useState } from "react";

function Contact() {
  return (
    <div className="sec section-terms">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h5 className="text-uppercase">
            FRACTIONAL {"//"} Contact Fractional
          </h5>
        </div>
      </div>
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12 h2-m">
          <h2 className="text-uppercase f-20 pb-3 text-gold">Contact us</h2>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <form class="row g-3">
            <div class="col-12">
              <label for="fullName" class="form-label">
                Full Name *
              </label>
              <input
                type="text"
                class="form-control"
                id="fullName"
                placeholder=""
              />
            </div>
            <div class="col-md-6">
              <label for="email" class="form-label">
                Email *
              </label>
              <input type="email" class="form-control" id="email" />
            </div>
            <div class="col-md-6">
              <label for="phone" class="form-label">
                Phone Number
              </label>
              <input type="text" class="form-control" id="phone" />
            </div>

            <div class="col-12">
              <label for="message" class="form-label">
                Message
              </label>
              <textarea
                class="form-control"
                id="message"
                rows="5"
                style={{ "line-height": "1.5" }}
                aria-label="With textarea"
              ></textarea>
            </div>

            <div class="col-12">
              <button
                type="button"
                className="btn btn-primary btn-lg btn-keep  text-uppercase btn-submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
