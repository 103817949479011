import "./App.css";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MainWebLayout from "./pages/main-web-layout";
import Home from "./pages/home";
import Terms from "./pages/terms-condition";
import PrivacyPolicy from "./pages/privacy-policy";
import FractionalOffer from "./pages/fractional-offer";
import PageNotFound from "./pages/page-not-found";
import CollectorPass from "./pages/collector-pass";
import Contact from "./pages/contact";
import Faqs from "./pages/faqs";
import FractionalConnect from "./pages/fractional-connect";
import CardanoMinting from "./pages/cardanoMinting";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          exact
          element={<MainWebLayout />}
        >
          <Route
            path='/'
            element={<Home />}
          />

          <Route
            path='/connect'
            element={<FractionalConnect />}
          />
          <Route
            path='/terms-and-conditions'
            element={<Terms />}
          />

          <Route
            path='/privacy-policy'
            element={<PrivacyPolicy />}
          />

          <Route
            path='/collectors-pass'
            element={<CollectorPass />}
          />

          <Route
            path='/faqs'
            element={<Faqs />}
          />

          <Route
            path='/contact-us'
            element={<Contact />}
          />

          <Route
            path='/cardano-minting'
            element={<CardanoMinting />}
          />

          <Route
            path='/fraction-offer/:id'
            element={<FractionalOffer />}
          />
          {/* The default 404 route */}
          <Route
            path='*'
            element={<PageNotFound />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
    // <div className="App">

    // </div>
  );
}

export default App;
