import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="sec section-terms">
      <div className="row m-0">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <h5>FRACTIONAL {"//"} PRIVACY POLICY</h5>
          <h2 className="text-uppercase h2-m text-gold ">PRIVACY POLICY</h2>
          <p className="text-justify text-p">
            We take privacy seriously, and your privacy is of the utmost importance to us.  This Privacy Policy applies to all services provided to you by Fractional, LTD / fractional.rocks.</p>
      <p className="text-justify text-p">
We reserve the right to change this Policy at any time.  You acknowledge that your continued use of the Services provided by Fractional, LTD / fractional.rocks is subject to any updated Policy.</p>
      <p className="text-justify text-p">
WHAT INFORMATION DO WE COLLECT?</p>
      <p className="text-justify text-p">
Due to the nature of non-fungible token and blockchain technology we collect and store no information directly relating to you.  A decentralized ledger allows your access and continued use of the Services.</p>
      <p className="text-justify text-p">
HOW DO WE USE YOUR INFORMATION?</p>
      <p className="text-justify text-p">
We may occasionally collect information regarding “usage” of the Services.  This information contains no identifiable information regarding you individually.</p>
      <p className="text-justify text-p">
DO WE SHARE YOUR INFORMATION?</p>
      <p className="text-justify text-p">
We do not rent, sell, or share any information with third-parties.</p>
      <p className="text-justify text-p">
HOW DO WE SECURE INFORMATION?</p>
      <p className="text-justify text-p">
Your information is secured through decentralized ledger technology on the blockchain. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
