import React, { Component } from "react";
import Slider from "react-slick";
import "../sipper.css";

import offerData from "../data/fractional-offer-data";
const Swipper = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slick-slider">
      <Slider {...settings}>
        {offerData.map((x) => {
          return (
            <div className="card">
              <img className="swipper-img" src={x.src} alt="" />
              <a
                href={!x.isSold ? `fraction-offer/${x.id}` : null}
                type="button"
                className="btn btn-primary btn-lg btn-swipper  text-uppercase cursor btn-link"
              >
                {x.name}
              </a>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default Swipper;
