import React from "react";

const CardanoMinting = () => {
  return (
    <div className='sec section-terms'>
      <div className='row m-0'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
          <h5>FRACTIONAL {"//"} CARDANO MINTING</h5>
          <h2 className='text-uppercase h2-m text-gold '>CARDANO MINTING</h2>
          <p className='text-justify text-p'>
           Please choose your desired mint quanity:</p>
  <ul>
    <li>1 Collectors Pass = 80 ADA</li>
    <li>2 Collectors Passes = 160 ADA</li>
    <li>3 Collectors Passes = 240 ADA</li>
    <li>4 Collectors Passes = 320 ADA</li>
    <li>5 Collectors Passes = 400 ADA</li>
    </ul>
           <p className='text-justify text-p'>
    Send total ADA amount to the following address:<br/>
    <b>addr1v9d0mmm9y8zqjhvt7p9n2jcfg9ug70yzjxgl06p83jf4eeq536w7d</b>
    </p>
         
         
         
        
         
        
         
         
       
        </div>
      </div>
    </div>
  );
};

export default CardanoMinting;
