import React from "react";
const PageNotFound = () => {
  return (
    <div className="sec section-terms page-note-found">
      <h5 className="text-uppercase m-0">Oops! Page not found</h5>
      <img
        src="/404-page-not-found-r.png"
        alt="404 Not Found"
        className="centered-image"
      />
      <h5 className="text-uppercase text-center">
        We are sorry, but the page you requested <br /> was not found
      </h5>

      <a
        href="/"
        type="button"
        className="btn btn-primary btn-lg btn-keep text-uppercase btn-back-home"
      >
        back to home
      </a>
    </div>
  );
};
export default PageNotFound;
