import React, { useRef, useState } from "react";
import "../team.css";

function Team() {
  const data = [
    {
      source: "./rendr.jpg",
      name: "RENDR",
      position: "Founder",
      skill: "Blockchain Developer",
      serialNo: "Mineralogist",
      profession: "Decentralization Advocate",
      insta: "#",
      twiter: "https://twitter.com/realrendr",
      facebook: "#",
      discord: "#",
    },
   
    {
      source: "./brit.jpg",
      name: "BRIT",
      position: "Public Rel.",
      skill: "Social Media",
      serialNo: "Accounting",
      profession: "Holder Relations",
      insta: "#",
      twiter: "#",
      facebook: "#",
      discord: "#",
    },
    {
      source: "./chris.jpg",
      name: "ROM",
      position: "Bus. Dev.",
      skill: "Consultant",
      serialNo: "Business Development",
      profession: "Sales",
      insta: "#",
      twiter: "#",
      facebook: "#",
      discord: "#",
    },
  
  ];
  return (
    <div className="my-team-main-div">
      <div className="staff-images">
        {data.map((e) => {
          return (
            <div className="staff-detail">
              <div className="staff-avatar">
                <img src={e.source} style={{ borderRadius: "50%" }} />
              </div>
              <h4>
                {e.name}, <span className="text-postion">{e.position}</span>
              </h4>
              <span className="text-uppercase">{e.skill}</span>
              <span className="text-uppercase">{e.serialNo}</span>
              <span className="text-uppercase">{e.profession}</span>
   <div className="staff-social-media-icon">
               
                {/* <div>
                  <a href={e.facebook}>
                    <img src="./icons/facbook.png" alt="" />
                  </a>
                </div> */}
              
                {/* <div>
                  <a href={e.discord}>
                    <img src="./icons/discord.png" alt="" />
                  </a>
                </div> */}
              </div> 
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Team;
