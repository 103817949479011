const customJs = () => {
  const navbar = document.querySelector(".navbar");
  window.addEventListener("scroll", () => {
    if (window.scrollY >= 56) {
      navbar.classList.add("navbar-scrolled");
    } else if (window.scrollY <= 56) {
      navbar.classList.remove("navbar-scrolled");
    }
  });
  const navbarLinks = navbar.querySelectorAll(".navbar-nav a");

  navbarLinks.forEach((link) => {
    link.addEventListener("click", () => {
      if (window.innerWidth <= 768) {
        const navbarCollapse = navbar.querySelector(".navbar-collapse");
        if (navbarCollapse.classList.contains("show")) {
          navbarCollapse.classList.remove("show");
        }
      }
    });
  });
};

export default customJs;
