const offerData = [
  {
    id: "SAMPLE",
    name: "collectors pass", // SOLD OUT
    src: "/pass.gif",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
    paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
    paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
    paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
    paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
    paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
  {
    id: "SAMPLE",
    name: "coming soon", // SOLD OUT
    src: "/image-cards.jpeg",
    availabilityDate: "JANUARY 2024",
    title: "THE SAMPLE SPECIMEN ",
    bracketsTitle: "specimen",
    isSold: false,
   paragraph: [
      "This is just a sample offering.  No product exists here.",
    ],
    price: "1.00",
  },
];

export default offerData;

// save if we need we will use it.
// chartData: {
//   data1: {
//     heading: "ASSET ACQUISITION",
//     percentage: 82,
//     subHeading: "Asset locating, acquisition, transportation, labor, etc.",
//   },
//   data2: {
//     heading: "ASSET MANAGEMENT",
//     percentage: 10,
//     subHeading: "Asset management, storage, display, insurance, etc.",
//   },
//   data3: {
//     heading: "MISCELLANOUS EXPENSES",
//     percentage: 5,
//     subHeading:
//       "All additional expenses including team payroll, unexpected costs, etc.",
//   },
//   data4: {
//     heading: "ADMINSITRATIVE FEES",
//     percentage: 3,
//     subHeading:
//       "Structure of offering, organization, operations, photography, graphic design, bookkeeping, etc.",
//   },
// },
